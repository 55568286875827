import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// import Box from '@material-ui/core/Box';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://ntad.net/">
        Network Tool and Die Company, Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
    theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const tiers = [
  {
    title: '100 Mbps',
    price: '250',
    description: [
      'Up to 100 Mbps Up and Down',
      'Supports 2-5 Users',
      'One static IPv4 address',
      'IP v4 and v6 netblocks available',
      'Taxes and fees included'
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
    buttonHref: 'https://serviceinquiry.marketing.ntad.net'
  },
  {
    title: '250 Mbps',
    price: '425',
    description: [
      'Up to 250 Mbps Up and Down',
      'Supports 5-10 Users',
      'One static IPv4 address',
      'IP v4 and v6 netblocks available',
      'Taxes and fees included'
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
    buttonHref: 'https://serviceinquiry.marketing.ntad.net'
  },
  {
    title: 'Gigabit',
    price: '750',
    description: [
      'Up to 940 Mbps Up and Down',
      'Enterprise Class',
      'One static IPv4 address',
      'IP v4 and v6 netblocks available',
      'Taxes and fees included'
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
    buttonHref: 'https://serviceinquiry.marketing.ntad.net'
  },
];

export default function Pricing() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
        <img src="./ntad-logo.svg" width="210" height="125" alt="Network Tool & Die" />
          <Typography variant="h3" color="inherit" noWrap className={classes.toolbarTitle}>
           Business Internet
          </Typography>
          <nav>
            <Link variant="button" color="textPrimary" href="#" className={classes.link}>
              
            </Link>
            <Link variant="button" color="textPrimary" href="#" className={classes.link}>
              
            </Link>
            <Link variant="button" color="textPrimary" href="#" className={classes.link}>
              
            </Link>
          </nav>
          <Button color="primary" variant="outlined" className={classes.link} href="https://serviceinquiry.marketing.ntad.net">
            Request a Consult
          </Button>
        </Toolbar>
      </AppBar>
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          125 N Market Business Internet Pricing
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
            It&apos;s easy to get NTAD Business Internet Installed in 3-5 business days.  Contact us now!
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /mo
                    </Typography>
                  </div>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} color="primary" href={tier.buttonHref}>
                    {tier.buttonText} 
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <p></p>
      <Copyright />
    </React.Fragment>

  );
}
